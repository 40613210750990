import Link from "next/link";
import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";

import { useUser } from "@/lib/hooks/useUser";
import { LoadingSpin } from "@/components/Others/Icons";
import Button from "@/components/Forms/Button";

const links = [
    {
        name: "Invite",
        href: "/invite",
    },
    {
        name: "Premium",
        className: "text-gold-default",
        href: "/premium",
    },
    {
        name: "Documentation",
        href: "/docs",
    },
    {
        name: "Support",
        href: "/support",
    },
];

export default function Navigation() {
    const [open, setOpen] = useState(false);
    const [parent] = useAutoAnimate<HTMLDivElement>();

    const AuthButton = () => {
        const { isLoading, user } = useUser();

        if (isLoading) {
            return (
                <Button light className="!w-full opacity-70 cursor-not-allowed">
                    <LoadingSpin />
                    Loading
                </Button>
            );
        }

        if (!user) {
            return (
                <Link href="/login">
                    <Button light className="!w-full">
                        Login
                    </Button>
                </Link>
            );
        }

        return (
            <div className="flex flex-col-reverse lg:flex-row items-center gap-4">
                <Link href="/dashboard">
                    <Button light className="flex flex-col md:flex-row items-center !px-6 !py-4 !w-full gap-2">
                        <div className="relative">
                            <img src={user.avatar_url} alt="Avatar" className={`w-12 h-12 rounded-full mr-2 ${user.is_premium && "outline outline-gold-default outline-2 outline-offset-2"}`} />
                            {user.is_premium && (
                                <img
                                    src="/images/twitter/emojis/crown.svg"
                                    alt=""
                                    className="w-[40px] h-[40px] absolute -top-4 right-2 translate-x-1/3 -translate-y-1/3 drop-shadow-2xl transform rotate-[25deg]"
                                />
                            )}
                        </div>
                        <div className="flex flex-col">
                            <span className="xl:block hidden text-base">
                                {user.discriminator == "0" ? (
                                    `@${user.name}`
                                ) : (
                                    <>
                                        {user.name}
                                        <span className="font-normal">#{user.discriminator}</span>
                                    </>
                                )}
                            </span>
                        </div>
                    </Button>
                </Link>

                <Link href="/logout" className="flex flex-row gap-4 items-center hover:opacity-70 duration-200">
                    <span className="lg:hidden">Logout</span>
                    <span className="material-icons-outlined text-2xl xl:text-4xl">logout</span>
                </Link>
            </div>
        );
    };

    return (
        <nav ref={parent} className="flex items-center justify-between gap-8">
            <Link href={"/"}>
                <img src="/images/logo.png" alt="..." className="cursor-pointer h-8 xl:h-12" />
            </Link>

            <div className="hidden lg:flex flex-row items-center gap-8 text-xl">
                {links.map((link, index) => (
                    <a key={index} href={link.href} target="_blank" rel="noreferrer" className={`hover:opacity-75 duration-200 ${link.className}`}>
                        {link.name}
                    </a>
                ))}

                <AuthButton />
            </div>

            <span className="material-icons-outlined cursor-pointer text-3xl lg:hidden hover:opacity-75 duration-200" onClick={() => setOpen(true)}>
                menu
            </span>

            {open && (
                <div className="fixed w-screen h-screen top-0 left-0 flex lg:hidden z-[999999]">
                    <div className="bg-blue-500 bg-opacity-50 w-full h-full" onClick={() => setOpen(false)} />

                    <div className="flex flex-col justify-between bg-gradient-to-b from-blue-400 to-blue-500 py-10 px-8 md:px-14">
                        <Link href={"/"}>
                            <img src="/images/logo.png" alt="Logo" className="cursor-pointer w-full" />
                        </Link>

                        <div className="flex flex-col gap-4 font-medium text-2xl text-center">
                            {links.map((link, index) => (
                                <a key={index} href={link.href} target={"_blank"} rel="noreferrer" className="hover:opacity-75 duration-200">
                                    {link.name}
                                </a>
                            ))}
                        </div>

                        <AuthButton />
                    </div>
                </div>
            )}
        </nav>
    );
}
