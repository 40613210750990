import type { ButtonHTMLAttributes, DetailedHTMLProps, ReactNode } from "react";
import { forwardRef } from "react";

export type Props = DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & {
    light?: boolean;
    children: ReactNode;
    className?: string;
};

const Button = forwardRef<HTMLButtonElement, Props>(function ButtonWrapper({ light, children, className, ...props }: Props, ref) {
    return (
        <button ref={ref} className={`btn flex gap-5 items-center justify-center w-max  ${light ? "bg-purple-100" : "bg-blue-400"} ${className}`} {...props}>
            {children}
        </button>
    );
});

export default Button;
