import { useContext, useEffect, useState } from "react";
import { UserContext } from "@/contexts/user";
import { fetchUser } from "@/lib/invite-manager-api/api";

export const useUser = () => {
    const { userState } = useContext(UserContext);
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<number>(null);
    useEffect(() => {
        if (userState.user) return;
        setLoading(true);
        fetchUser()
            .then(({ data, response }) => {
                if (!response.ok) {
                    setError(response.status);
                    return;
                }
                userState.user = data;
            })
            .finally(() => setLoading(false));
    }, []);

    return { user: userState.user, isLoading, error };
};
